@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

body {
    background-color: #000000;
    font: 16px/1.5 'Ubuntu', sans-serif;
}

button {
    transition: background-color 0.5s ease;
}

button:hover {
    color: #000;
    background-color: #ffffff;
}

a {
    transition: color 0.5s ease;
}

a:hover {
    color: rgb(202, 33, 33);
}

.logo {
    transition: fill 0.5s ease;
    width: 10rem;
    margin-top: 3rem;
    cursor: pointer;
}

.logo:hover {
    fill: #CA2121FF;
}

.navbar {
    position: sticky;
    top: 0;
    background: #000000;
    z-index: 1000;
}

.footer {
    background: #000000;
    z-index: 1000;
}

@media (min-width: 767px) {
    .footer {
        position: sticky;
        bottom: 0;
    }
}

@media (max-width: 767px) {
    .footer {
        position: relative;
        text-align: center;
        font-size: 0.7rem;
    }
}

@media (max-width: 340px) {
    .footer {
        font-size: 0.6rem;
    }
}

.link {
    transition: color 0.5s ease;
    color: #ffffff;
}

.link_active {
    font-weight: 900;
    color: #CA2121FF;
    text-shadow: 0 0 0.1rem #ff0000;
}

.anchor {
    display: block;
    position: relative;
    top: -15rem;
    visibility: hidden;
}
.rhap_container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    line-height: 1;
    font-family: inherit;
    width: 100%;
    padding: 10px 5%;
    background-color: #991b1b;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.rhap_container:focus:not(:focus-visible) {
    outline: 0;
}

.rhap_container svg {
    vertical-align: initial;
}

.rhap_header {
    color: #eaeaea;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: clamp(14px, 2vw, 16px);
}

.rhap_footer {
    margin-top: 5px;
}

.rhap_main {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.rhap_stacked .rhap_controls-section {
    margin-top: 8px;
}

.rhap_horizontal {
    flex-direction: row;
}

.rhap_horizontal .rhap_controls-section {
    margin-left: 8px;
}

.rhap_horizontal-reverse {
    flex-direction: row-reverse;
}

.rhap_horizontal-reverse .rhap_controls-section {
    margin-right: 8px;
}

.rhap_stacked-reverse {
    flex-direction: column-reverse;
}

.rhap_stacked-reverse .rhap_controls-section {
    margin-bottom: 8px;
}

.rhap_progress-section {
    display: flex;
    flex: 3 1 auto;
    align-items: center;
}

.rhap_progress-container {
    display: flex;
    align-items: center;
    height: 20px;
    flex: 1 0 auto;
    align-self: center;
    margin: 0 calc(10px + 1%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
}

.rhap_progress-container:focus:not(:focus-visible) {
    outline: 0;
}

.rhap_time {
    color: #eaeaea;
    font-size: clamp(12px, 1.5vw, 16px);
    user-select: none;
    -webkit-user-select: none;
}

.rhap_progress-bar {
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    width: 100%;
    height: 5px;
    background-color: #dddddd;
    border-radius: 2px;
}

.rhap_progress-filled {
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: #ffffff;
    border-radius: 2px;
}

.rhap_progress-bar-show-download {
    background-color: rgba(221, 221, 221, 0.5);
}

.rhap_download-progress {
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: #d1d1d1;
    border-radius: 2px;
}

.rhap_progress-indicator {
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    width: 10px;
    height: 10px;
    margin-left: -5px;
    top: -3px;
    background: #ffffff;
    border-radius: 50px;
    box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
}

.rhap_controls-section {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    align-items: center;
}

.rhap_additional-controls {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
}

.rhap_repeat-button {
    visibility: hidden;
}

.rhap_main-controls {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rhap_main-controls-button {
    margin: 0 3px;
    color: #eaeaea;
    font-size: 35px;
    width: 35px;
    height: 35px;
}

.rhap_play-pause-button {
    font-size: 40px;
    width: 40px;
    height: 40px;
}

.rhap_volume-controls {
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-end;
    align-items: center;
    margin-right: 3px;
}

.rhap_volume-button {
    flex: 0 0 26px;
    font-size: 26px;
    width: 26px;
    height: 26px;
    color: #eaeaea;
    margin-right: 6px;
}

.rhap_volume-container {
    display: flex;
    align-items: center;
    flex: 0 1 100px;
    user-select: none;
    -webkit-user-select: none;
}

.rhap_volume-bar-area {
    display: flex;
    align-items: center;
    width: 100%;
    height: 14px;
    cursor: pointer;
}

.rhap_volume-bar-area:focus:not(:focus-visible) {
    outline: 0;
}

.rhap_volume-bar {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    margin-right: 10px;
    height: 4px;
    background: #b1b1b1;
    border-radius: 2px;
}

.rhap_volume-indicator {
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    margin-left: -6px;
    left: 0;
    top: -3px;
    background: #ffffff;
    opacity: 0.9;
    border-radius: 50px;
    box-shadow: rgba(134, 134, 134, 0.5) 0 0 3px;
    cursor: pointer;
}

.rhap_volume-indicator:hover {
    opacity: 0.9;
}

.rhap_volume-filled {
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: #ffffff;
    border-radius: 2px;
}

.rhap_button-clear {
    background-color: transparent;
    border: none;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
}

.rhap_button-clear:hover {
    background: none;
    color: #ffffff;
    transition-duration: 0.2s;
}

.rhap_button-clear:active {
    opacity: 0.95;
}

.rhap_button-clear:focus:not(:focus-visible) {
    outline: 0;
}

.active_track {
    font-weight: 700;
    color: #ffffff;
    text-shadow: 0 0 0.09rem #ff0000;
    background-color: rgba(0, 0, 0, 0.34);
    border-radius: 0.5rem;
    display: inline-block;
    width: 100%;
}

.passive_track {
    color: #eaeaea;
}

.passive_track:hover {
    color: #ffffff;
    font-weight: 700;
    text-shadow: 0 0 0.1rem #ff0000;
}

.playlist {
    text-align: left;
    width: 100%;
    background-color: rgb(153, 27, 27);
    max-height: 300px;
    overflow-y: auto;
    scroll-padding-top: 1rem;
}

.playlist::-webkit-scrollbar {
    width: 8px;
}

.playlist::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
}

.playlist::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
}

/*media queries*/
@media (min-width: 768px) {
    .rhap_container {
        padding: 10px 15px;
    }

    .rhap_main-controls-button {
        margin: 0 8px;
    }
}

@media (max-width: 1300px) {
    .rhap_container {
        width: 120%;
    }

    .playlist {
        width: 120%;
    }
}

@media (max-width: 1121px) {
    .rhap_container {
        width: 150%;
    }

    .playlist {
        width: 150%;
    }
}

@media (max-width: 1021px) {
    .rhap_container {
        width: 160%;
    }

    .playlist {
        width: 160%;
    }
}

@media (max-width: 935px) {
    .rhap_container {
        width: 170%;
    }

    .playlist {
        width: 170%;
    }
}

@media (max-width: 850px) {
    .rhap_container {
        width: 180%;
    }

    .playlist {
        width: 180%;
    }
}

@media (max-width: 800px) {
    .rhap_container {
        width: 190%;
    }

    .playlist {
        width: 190%;
    }
}

@media (max-width: 767px) {
    .rhap_progress-section {
        flex-direction: column;
    }

    .rhap_progress-container, .rhap_time {
        width: 100%;
        margin-top: 5px;
    }

    .rhap_container {
        width: 210%;
    }

    .playlist {
        width: 210%;
    }
}

@media (max-width: 480px) {
    .rhap_header, .rhap_footer {
        font-size: 14px;
    }

    .rhap_main-controls-button, .rhap_play-pause-button {
        width: 30px;
        height: 30px;
        font-size: 30px;
    }

    .playlist {
        font-size: 13px;
    }
}

@media (max-width: 336px) {
    .playlist {
        font-size: 12px;
    }
}

@media (max-width: 320px) {
    .playlist {
        font-size: 11px;
    }
}

@media (max-width: 300px) {
    .playlist {
        font-size: 10px;
    }
}

@media (max-width: 767px) {
    .rhap_main-controls-button, .rhap_play-pause-button {
        font-size: 30px;
        width: 28px;
        height: 28px;
        margin: 0 2px;
    }

    .rhap_volume-button {
        font-size: 20px;
        width: 20px;
        height: 20px;
        margin-right: 4px;
    }

    .rhap_volume-controls, .rhap_additional-controls {
        flex-wrap: wrap;
    }
}

@media (max-width: 480px) {
    .rhap_main-controls-button, .rhap_play-pause-button {
        font-size: 24px;
        width: 24px;
        height: 24px;
        margin: 0 1px;
    }

    .rhap_volume-button {
        font-size: 18px;
        width: 18px;
        height: 18px;
    }

    .rhap_container {
        width: 250%;
    }

    .playlist {
        width: 250%;
    }
}